import { Button, Typography, withStyles, withTheme } from "@material-ui/core";
import React from "react";

interface FooterProps {
  classes?: any;
}

const styles = (theme: any): any => ({
  wrapper: {
    padding: "32px 0",
    borderTop: `1px solid ${theme.newPalette.other.divider}`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 24px",
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    gap: "32px",
    "@media (max-width: 700px)": {
      alignItems: "start",
    },
  },
  footerSection: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 700px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  copyrightText: {
    color: theme.newPalette.text.secondary,
  },
  subtitle2Text: {
    color: theme.newPalette.text.primary,
  },
  findOutBtn: {
    padding: "8px 22px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    backgroundColor: theme.newPalette.testrtc.primary,
  },
});

const Footer = ({ classes }: FooterProps) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.footerSection}>
          <img src="/testRTC.png" alt="testRTC" width={112} />
          <Typography variant="subtitle2" className={classes.subtitle2Text}>
            qualityRTC: Solve WebRTC connectivity and quality issues faster for your users.
          </Typography>
          <Button
            className={classes.findOutBtn}
            href="https://cyara.com/products/qualityrtc/"
            target="_blank"
          >
            Find out more
          </Button>
        </div>
        <Typography variant="body2" className={classes.copyrightText}>
          Copyright 2024 testRTC | All Rights Reserved
        </Typography>
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(Footer));
