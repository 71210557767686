import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";
import { format, parseISO } from "date-fns";
import { ITestsResult } from "twillio-tests/core/TestResults";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { startTest, sendReport, sendEmail, startTestLoading } from "../../actions/actions";
import { IReportOptions, IField } from "../../interfaces";
import {
  checkPassword,
  isValidRegExp,
  getQueryParameters,
  checkPasswordOld,
} from "../../helpers/utils";
import { ReloadIcon, DownloadIcon, ExclamationMarkIcon } from "../icons";

import PasswordModal from "../PasswordModal/PasswordModal";
import { LogModalContext } from "../LogModal/LogModalProvider";

import Field from "./Field";
import "./ControlBar.scss";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import LivevoxCustomComponent from "../CustomerSpecificComponent/livevoxCustomComponent";
import { Grid } from "@material-ui/core";
import NewControlBar from "./NewControlBar";
import Onboarding from "../Onboarding/Onboarding";
import { TestState } from "../MainContent/MainContent";
import CustomTooltip from "../TestsContainer/Tests/NewTests/UI/CustomTooltip";

interface IControlBarProps {
  testResult: ITestsResult;
  testsList: string[];
  createDate: any;
  config: TestConfiguration;
  fields: IField[];
  uuid?: string;
  classes: any;
  isReady: boolean;
  theme: any;
  currentTheme: string;
  email: string;
  title: string;
  tests: any;
  sendEmailStatus: any;
  testIsLoading: boolean;
  hideInstallExtensionModal: boolean;
  onStartTestLoading(): void;
  onStartTests(testsList: string[], config: any): void;
  sendReport(fieldOption: IReportOptions, config: any): void;
  sendEmail(fields: IValueState, config: TestConfiguration, uuid: string): void;
  testState?: TestState;
  setTestState?: Dispatch<SetStateAction<TestState>>;
  onboardingClasses?: any;
  showMore?: boolean;
  showExtension: (arg: boolean) => void;
  showCompanionModal: boolean;
  setShowCompanionModal: Dispatch<SetStateAction<boolean>>;
}
interface ILogItem {
  message: Object;
  color: string;
  time: string;
}
export interface IMetaState {
  [field: string]: IField;
}

export interface IValueState {
  [field: string]: string;
}

interface ISetFieldMeta {
  fieldName: string;
  metaName: string;
  value: any;
}

const styles = (theme: any) => ({
  startButton: {
    minWidth: 200,
    margin: "auto",
    display: "block",
  },
  startButton_mend: {
    backgroundColor: "rgb(235, 28, 36)",
    "&:hover": {
      background: "rgb(235, 28, 36)",
      opacity: 0.8,
    },
  },
  startButton_itracks: {
    fontFamily: "PT Sans",
    fontStyle: "normal",
    fontWeight: 400,
  },
  startButton_avatour: {
    background: "#ff4e00",
    "&:hover": {
      background: "#ff4e00",
      opacity: 0.8,
    },
  },
  startButton_dialpad: {
    background: "rgb(124, 82, 255)",
    borderRadius: "30px",
    "&:hover": {
      background: "rgb(124, 82, 255)",
      opacity: 0.8,
    },
  },
  startButton_livevox: {
    background: "#212721",
    "&:hover": {
      background: "#212721",
      opacity: 0.8,
    },
  },
  startButton_logitech: {
    background: "black",
  },
  startButton_salesloft: {
    background: "#B5D626",
    color: "#0A2417",
    "&:hover": {
      color: "#f2f2f2",
    },
  },
  startButtonText: {
    height: "auto",
    minHeight: "45px",
  },
  exclamationMarkIconError: {
    position: "absolute" as "absolute",
    top: -10,
    right: -16,
    color: theme.customColors.errorColor,
  },
  exclamationMarkIconWarn: {
    position: "absolute" as "absolute",
    top: -10,
    right: -16,
    color: theme.customColors.warningColor,
  },
  logBtn: {
    borderColor: theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    height: 45,
    borderWidth: 2,
    marginRight: 15,
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 600,
    "&:last-child": {
      marginRight: 0,
    },
    "&[disabled]": {
      borderColor: theme.customColors.disabledColor,
      color: theme.customColors.disabledColor,
      borderWidth: 2,
    },
  },
  logBtn_glia: {
    color: "#2c0735",
    borderColor: "#2c0735",
  },
  logBtn_livevox: {
    color: "#212721",
    borderColor: "#212721",
  },
  logBtn_dialpad: {
    color: "#212721",
    borderColor: "#212721",
    borderRadius: "8px",
  },
  logBtn_avatour: {
    color: "#ff4e00",
    borderColor: "#ff4e00",
  },
  logBtn_mend: {
    fontWeight: 500,
  },
  logBtn_amazon: {
    fontWeight: 400,
  },
  form: {
    background: "#fff",
    padding: 5,
    display: "flex",
  },
  "form-freshworks": {
    padding: 0,
    borderRadius: 3,
    border: "1px solid #ccc",
  },
  textField: {
    background: "transparent",
    margin: 0,
    borderLeft: "1px solid #c9cbd1",
    padding: "0 15px",
    justifyContent: "center",
    minWidth: "100px",
    "&:focus": {
      background: "#000",
    },
    "&:first-child": {
      borderLeft: 0,
    },
    "& > div": {
      fontSize: 14,
      fontWeight: 600,
    },
    "& > div:before": {
      display: "none",
    },
    "& > div:after": {
      display: "none",
    },
  },
  sendButton: {},
  sendButton_freshworks: {
    borderRadius: 2,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    fontSize: 10,
    textTransform: "capitalize" as "capitalize",
  },
  greenBg: {
    background: theme.customColors.greenColor,
  },
  greenBorder: {
    borderColor: theme.customColors.greenColor,
    color: theme.customColors.greenColor,
  },
  salesLoftBorder: {
    borderColor: theme.customColors.mainColor,
    color: theme.customColors.mainColor,
  },
  whiteBorder: {
    borderColor: theme.customColors.whiteColor,
    color: theme.customColors.whiteColor,
  },
  startButton_puzzel: {
    background: "#FF005D",
  },
  pinkBorder: {
    borderColor: "#ccc",
    color: theme.palette.primary.main,
    borderWidth: 1,
  },
  amazonBorder: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  zaurusBorder: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },

  createDate: {
    fontSize: 14,
    fontWeight: 600,
  },
  createDate_dialpad: {
    fontSize: 15,
    fontWeight: 500,
  },
  createDate_avatour: {
    color: "#fff",
  },
  uuid: {
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
  },
  uuid_dialpad: {
    fontSize: 15,
    fontWeight: 500,
  },
  uuid_avatour: {
    color: "#fff",
  },
});

function mapStateToProps(state: any) {
  return {
    tests: state.tests,
    testIsLoading: state.document.testIsLoading,
    testsList: state.tests.testsList,
    createDate: state.tests.createDate,
    isReady: state.tests.isReady,
    testResult: state.tests.testResult,
    currentTheme: state.document.theme,
    fields: state.document.fields || [],
    uuid: state.document.uuid,
    sendEmailStatus: state.document.sendEmailStatus,
    config: state.tests.config,
    email: state.tests.email,
    title: state.tests.title,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    onStartTestLoading() {
      dispatch(startTestLoading());
    },
    onStartTests(testsList: string[], config: any) {
      dispatch(startTest(testsList, config));
    },
    sendReport(fieldOption: IReportOptions, config: any) {
      dispatch(sendReport(fieldOption, config));
    },
    sendEmail(fields: IValueState, config: TestConfiguration, uuid: string) {
      dispatch(sendEmail(fields, config, uuid));
    },
  };
}

export const OptionalTooltip = ({ children, title, show, className, config }: any) => {
  if (show && config?.useNewWidgetDesign) {
    return (
      <CustomTooltip title={title} disableFocusListener={true}>
        {children}
      </CustomTooltip>
    );
  } else if (show) {
    return (
      <Tooltip title={title} className={className ? className : ""} disableFocusListener={true}>
        {children}
      </Tooltip>
    );
  } else {
    return <>{children}</>;
  }
};

const ControlBar = (props: IControlBarProps) => {
  const {
    classes,
    theme,
    fields,
    isReady,
    currentTheme,
    testResult,
    email,
    title,
    uuid,
    tests,
    config,
    sendEmailStatus,
    testIsLoading,
    hideInstallExtensionModal,
    testState,
    setTestState,
    onboardingClasses,
    showMore,
    showExtension,
    showCompanionModal,
    setShowCompanionModal,
  } = props;
  let testStartTime = props?.createDate;
  // if (props.createDate) {
  //   props.tests.logs.forEach((i: ILogItem) => {
  //     if (i.message) {
  //       if (i?.message["OS"]) {
  //         testStartTime = i.message["Timezone"].split(";")[0];
  //         testStartTime = new Date(testStartTime);
  //         testStartTime = testStartTime.toISOString();
  //       }
  //     }
  //   });
  // }
  const logModalContext = React.useContext(LogModalContext);
  const [authOk, setAuthOk] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordModalOpen, setPasswordModalState] = useState(false);
  const [showStartBtn, toggelStartBtn] = useState(true);
  const [isDownloadingReport, setIsDownloadingReport] = React.useState(false);
  const showControlButtons =
    config && (config.options.hidewidgets === "true" || config.isClientPortal) ? false : true;
  const [values, setValues] = React.useState<IValueState>({});
  const [meta, setMeta] = React.useState<IMetaState>({});
  let isOldPasswordSystem = false;
  const { t } = useTranslation(["common", "tests"]);
  const sendEmail = () => {
    const isValid = validate();
    if (isValid && values["email"] && uuid) {
      props.sendEmail(values, props.config, uuid);
    }
    //if(values['email'])
  };
  const createFieldsState = (fields: any[]) => {
    const valuesState: IValueState = {};
    const metaState: IMetaState = {};
    const queryParams = getQueryParameters();
    const uuid: any = window.location.pathname.replace("/", "");

    const backwardCompatibilityValues = tests || {};

    fields.forEach((field) => {
      const value = backwardCompatibilityValues[field.name] || queryParams[field.name] || "";
      const userReasonCondition = queryParams.user && queryParams.reason;

      valuesState[field.name] = value;
      const label = (!value && field.stubValue && uuid ? field.stubValue : field.label) || "";

      metaState[field.name] = {
        ...field,
        label: label,
        readonly: !!value || !!uuid,
        hidden:
          currentTheme === "dialpad"
            ? false
            : field.hidden || userReasonCondition || queryParams[field.name],
      };
    });
    setValues(valuesState);
    setMeta(metaState);
  };
  const moreBtnDisabled = !isReady || (isReady && !testResult);

  React.useEffect(() => {
    createFieldsState(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, fields]);
  React.useEffect(() => {}, [props.createDate]);

  const handleChange = (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [fieldName]: event.target.value });
    setSingleFieldMeta({ fieldName, metaName: "error", value: false });
  };

  const validate = () => {
    const errorFieldsMetas: ISetFieldMeta[] = [];
    fields.forEach((field) => {
      const requiredError = meta[field.name].required && !values[field.name];
      const exp = meta[field.name].validationExpression;
      const validationError =
        isValidRegExp(exp) && exp && !new RegExp(exp).test(values[field.name]);
      const fieldHasError = requiredError || validationError;
      if (fieldHasError) {
        errorFieldsMetas.push({ fieldName: field.name, metaName: "error", value: true });
      }
    });
    setFieldsMeta(errorFieldsMetas);
    return errorFieldsMetas.length === 0;
  };

  const setSingleFieldMeta = (data: ISetFieldMeta) => {
    setMeta({
      ...meta,
      [data.fieldName]: {
        ...meta[data.fieldName],
        [data.metaName]: data.value,
      },
    });
  };

  const setFieldsMeta = (data: ISetFieldMeta[]) => {
    const newMeta = { ...meta };
    data.forEach((d) => {
      newMeta[d.fieldName] = {
        ...newMeta[d.fieldName],
        [d.metaName]: d.value,
      };
    });
    setMeta(newMeta);
  };

  const hasUuidInUrl = () => {
    return !!window.location.pathname.replace("/", "");
  };

  const handleDownload = async () => {
    try {
      const isOptions = Object.keys(config.options).length;
      let newUrl = window.location.href;
      if (isOptions > 0) {
        newUrl = `${window.location.href}&report=pdf`;
      } else {
        newUrl = `${window.location.href}?report=pdf`;
      }
      window.open(newUrl, "_blank");
    } catch (err) {
      console.error("handleDownload", { err: err.stack });
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const handleStartTest = async () => {
    let extensionValue = await extensionCheck();
    const isValid = validate();
    if (!isValid) {
      return;
    }

    if (authOk || !props.config.userRequirePassword) {
      if (extensionValue) {
        startTest(props.testsList, props.config);
      } else {
        props.onStartTestLoading();
      }
    } else {
      setIsPasswordValid(true);
      setPasswordModalState(true);
    }
  };
  /**
   * Asynchronous function to handle reloading the test.
   */
  const handleReloadTest = async () => {
    try {
      // Check if extension is available
      let extensionValue = await extensionCheck();

      // If extension is available, start the test
      if (extensionValue) {
        startTest(props.testsList, props.config);
      }
      // Otherwise, notify the user or perform necessary action
      else {
        props.onStartTestLoading();
      }
    } catch (error) {
      // Handle any potential errors gracefully
      console.error("An error occurred while reloading the test:", error);
    }
  };

  const handlePasswordSubmitOld = (value: string) => {
    checkPasswordOld(props.config.userAuthKey as number[], value).then((passwordMatch: boolean) => {
      if (passwordMatch) {
        setAuthOk(true);
        setPasswordModalState(false);
        if (hideInstallExtensionModal) {
          startTest(props.testsList, props.config);
        } else {
          props.onStartTestLoading();
        }
      } else {
        setIsPasswordValid(false);
      }
    });
  };
  const extensionCheck = async () => {
    const isCompanionForced = config?.options?.companion === "force";

    if (isCompanionForced) {
      const isChrome =
        navigator.userAgent.includes("Chrome") && typeof chrome !== "undefined" && chrome.runtime;
      const extensionId = "ninnkfgcogbifinffmalccnoijlhhnok";

      if (isChrome) {
        return new Promise((resolve, reject) => {
          chrome.runtime.sendMessage(extensionId, { action: "GET_EXTENSION_INFO" }, (response) => {
            if (!chrome.runtime.lastError) {
              showExtension(true);
              setShowCompanionModal(false);
              resolve(true);
            } else {
              showExtension(false);
              setShowCompanionModal(true);
              resolve(false);
            }
          });
        });
      } else {
        showExtension(false);
        setShowCompanionModal(true);
        return false;
      }
    } else {
      showExtension(true);
      setShowCompanionModal(false);
      return true;
    }
  };

  const handlePasswordSubmit = (value: string) => {
    const { config } = props;

    checkPassword(value, config).then((passwordMatch: boolean) => {
      if (passwordMatch) {
        setAuthOk(true);
        setPasswordModalState(false);
        if (hideInstallExtensionModal) {
          startTest(props.testsList, props.config);
        } else {
          props.onStartTestLoading();
        }
      } else {
        setIsPasswordValid(false);
      }
    });
  };

  const controlBarMoreBtnClassName = () => {
    return `${classes.logBtn} ${classes[`logBtn_${currentTheme}`]} control-bar-more__btn ${
      currentTheme === "blue"
        ? classes.greenBorder
        : currentTheme === "salesloft"
        ? classes.salesLoftBorder
        : currentTheme === "freshworks"
        ? classes.pinkBorder
        : currentTheme === "amazon"
        ? classes.amazonBorder
        : currentTheme === "zaurus"
        ? classes.zaurusBorder
        : ""
    }`;
  };
  const grigFieldStyle = (index: number) => {
    return index === 0 || index === 1 ? 6 : 12;
  };
  const fieldStyle = (length: number, index: number) => {
    return index === 0 || index === 1
      ? {
          width: "100%",
          marginBottom: length > 2 ? 5 : 0,
        }
      : {
          width: "100%",
          border: "none",
          marginBottom: 5,
        };
  };

  const startTest = (testsList: string[], config: any) => {
    config = {
      ...config,
      fieldsValues: values,
    };
    props.onStartTests(testsList, config);
    setTestState?.("inProgress");
  };

  const testHasRequiredFields = fields.some((x) => x.required || x.validationExpression);
  const testStatus = testResult?.status;
  if (config?.inviteFields && config?.inviteFields.reason) {
    config.inviteFields.title = config.inviteFields.reason;
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("lang", lang);
    var title: string = document.getElementsByTagName("title")[0].innerHTML;
    window.history.pushState(
      {},
      title,
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  };
  const supportedLanguages: string[] =
    config && config.supportedLanguages ? config.supportedLanguages : [];
  const current_language: string = i18n.language || window.localStorage.i18nextLng;
  if (
    config &&
    supportedLanguages &&
    supportedLanguages.length &&
    supportedLanguages.indexOf(current_language) === -1
  ) {
    changeLanguage("en");
  }

  const handleChangeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const lang: string = event.target.value;
    changeLanguage(lang);
  };

  const showLanguageDropdown: boolean =
    config && config.showLanguageSelect ? config.showLanguageSelect : false;
  if (config && config.useNewWidgetDesign) {
    if (props.testResult && Object.keys(props.testResult).length > 0) {
      if (showMore) {
        return (
          <>
            <NewControlBar
              isReady={isReady}
              testIsLoading={testIsLoading}
              uuid={uuid}
              testStartTime={testStartTime}
              config={config}
              moreBtnDisabled={moreBtnDisabled}
              testStatus={testStatus}
              openLog={() => logModalContext.setOpen(true)}
              currentTheme={currentTheme}
              reloadClick={() => handleReloadTest()}
              showControlButtons={showControlButtons}
              downloadClick={handleDownload}
              isDownloadingReport={isDownloadingReport}
              values={values}
              fields={fields}
              meta={meta}
              showLanguageDropdown={showLanguageDropdown}
              supportedLanguages={supportedLanguages}
              handleChangeLanguage={handleChangeLanguage}
              current_language={current_language}
              showCompanionModal={showCompanionModal}
            />
            <PasswordModal
              open={passwordModalOpen}
              isValid={isPasswordValid}
              handleClose={() => setPasswordModalState(false)}
              onSubmit={isOldPasswordSystem ? handlePasswordSubmitOld : handlePasswordSubmit}
              theme={currentTheme}
              config={config}
            />
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <>
          <Onboarding
            testState={testState}
            setTestState={setTestState}
            onboardingClasses={onboardingClasses}
            startTest={handleStartTest}
            theme={currentTheme}
            values={values}
            fields={fields}
            meta={meta}
            config={config}
            handleChange={handleChange}
            showCompanionModal={showCompanionModal}
            setShowCompanionModal={setShowCompanionModal}
          />
          <PasswordModal
            open={passwordModalOpen}
            isValid={isPasswordValid}
            handleClose={() => setPasswordModalState(false)}
            onSubmit={isOldPasswordSystem ? handlePasswordSubmitOld : handlePasswordSubmit}
            theme={currentTheme}
            config={config}
          />
        </>
      );
    }
  }

  return (
    <div>
      {currentTheme === "livevox" && (
        <LivevoxCustomComponent
          hasUuidInUrl={hasUuidInUrl()}
          moreBtnDisabled={moreBtnDisabled}
          handleStartTest={() => handleStartTest()}
        />
      )}
      <div className="control-bar">
        {config && config.options.hidewidgets !== "true" && (
          <React.Fragment>
            <div className={`control-bar-item control-bar-left`}>
              {Object.keys(values).length > 0 &&
                fields.map((field, index) => {
                  if (meta[field.name].hidden) {
                    return null;
                  }
                  if (config?.inviteFields && config.inviteFields[field.name]) {
                    values[field.name] = config.inviteFields[field.name];
                  }
                  return (
                    <OptionalTooltip
                      key={field.name}
                      title={values[field.name]}
                      show={meta[field.name].readonly}
                    >
                      <Grid item xs={grigFieldStyle(index)} className="tooltipover">
                        <Field
                          theme={theme}
                          currentTheme={currentTheme}
                          name={field.name}
                          value={values[field.name]}
                          meta={meta[field.name]}
                          onChange={handleChange(field.name)}
                          style={fieldStyle(fields.length, index)}
                          config={config}
                          onboardingClasses={onboardingClasses}
                        />
                      </Grid>
                    </OptionalTooltip>
                  );
                })}
            </div>
            {config?.theme === "availconsole" && (
              <div className={`availConsoleTitles`}>
                {config?.options?.organization && (
                  <p className={`availOrgField`}>{config.options.organization}</p>
                )}
                {config?.options?.clientFirstName && (
                  <p className={`availNameField`}>{config.options.clientFirstName}</p>
                )}
              </div>
            )}
          </React.Fragment>
        )}
        {/* <div className={`control-bar-item control-bar-left`}>
          {Object.keys(values).length > 0 &&
            fields.map((field, index) => {
              if (meta[field.name].hidden) {
                return null;
              }
              if (config?.inviteFields && config.inviteFields[field.name]) {
                values[field.name] = config.inviteFields[field.name];
              }
              return (
                <OptionalTooltip
                  key={field.name}
                  title={values[field.name]}
                  show={meta[field.name].readonly}
                >
                  <Field
                    theme={theme}
                    currentTheme={currentTheme}
                    name={field.name}
                    value={values[field.name]}
                    meta={meta[field.name]}
                    onChange={handleChange(field.name)}
                    style={fieldStyle(fields.length, index)}
                  />
                </OptionalTooltip>
              );
            })}
        </div> */}

        <div className={`control-bar-item control-bar-center`}>
          {currentTheme !== "livevox" && moreBtnDisabled && !hasUuidInUrl() && (
            <Button
              data-html2canvas-ignore
              id="start"
              className={`${classes.startButton} ${classes[`startButton_${currentTheme}`]}`}
              onClick={handleStartTest}
            >
              {config?.theme === "availconsole" ? t("test") : t("start")}
            </Button>
          )}
          {isReady && !testIsLoading && uuid && (
            <p className={`${classes.createDate} ${classes[`createDate_${currentTheme}`]}`}>
              {format(
                parseISO(testStartTime ? testStartTime : new Date().toISOString()),
                "MMM dd, yyyy - HH:mm"
              )}
            </p>
          )}
          {isReady && uuid && (
            <Tooltip title={"Click to copy to clipboard"}>
              <p
                className={`${classes.uuid} ${classes[`uuid_${currentTheme}`]}`}
                onClick={() => {
                  navigator.clipboard.writeText(uuid as string);
                }}
              >
                {uuid}
              </p>
            </Tooltip>
          )}
        </div>
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          {showControlButtons && (
            <div data-html2canvas-ignore className={`control-bar-item control-bar-right`}>
              {!props.config?.hideDownloadButton && (
                <Button
                  id="download"
                  variant="outlined"
                  disabled={moreBtnDisabled || isDownloadingReport}
                  className={controlBarMoreBtnClassName()}
                  onClick={handleDownload}
                >
                  <DownloadIcon
                    fill={
                      moreBtnDisabled
                        ? theme.customColors.disabledColor
                        : theme.palette.primary.dark
                    }
                  />
                </Button>
              )}

              {props.config?.showSendNotificationButton && (
                <Button
                  id="download"
                  variant="outlined"
                  disabled={
                    moreBtnDisabled || isDownloadingReport || sendEmailStatus === "sending" || !uuid
                  }
                  className={controlBarMoreBtnClassName()}
                  onClick={sendEmail}
                >
                  {!sendEmailStatus || sendEmailStatus === "sent" ? "Send Email" : "Sending Email"}
                </Button>
              )}
              {currentTheme !== "dialpad" && (
                <Button
                  id="reload"
                  variant="outlined"
                  disabled={moreBtnDisabled}
                  className={controlBarMoreBtnClassName()}
                  onClick={() => handleReloadTest()}
                >
                  <ReloadIcon
                    fill={
                      moreBtnDisabled
                        ? theme.customColors.disabledColor
                        : theme.palette.primary.dark
                    }
                  />
                </Button>
              )}

              <Button
                id="log"
                variant="outlined"
                disabled={
                  !props.config?.debug && (moreBtnDisabled || props.config?.logRestrictAccess)
                }
                className={controlBarMoreBtnClassName()}
                onClick={() => logModalContext.setOpen(true)}
              >
                {(testStatus === "yellow" || testStatus === "red") && (
                  <ExclamationMarkIcon
                    className={
                      testStatus === "yellow"
                        ? classes.exclamationMarkIconWarn
                        : classes.exclamationMarkIconError
                    }
                  />
                )}
                {t("log")}
              </Button>
            </div>
          )}

          {config?.useNewWidgetDesign && showLanguageDropdown && supportedLanguages.length > 0 && (
            <FormControl
              variant="outlined"
              className={`${classes.formControl} formControl-Inputfield`}
              style={{
                width: "150px",
                margin: "0 0 0 12px",
                textAlign: "center",
              }}
            >
              <InputLabel htmlFor="select-language">{t("language")}</InputLabel>
              <Select
                value={current_language}
                onChange={handleChangeLanguage}
                input={
                  <OutlinedInput labelWidth={t("language").length * 8.5} id="select-language" />
                }
              >
                {supportedLanguages.map((lng: string, key: number) => {
                  return (
                    <MenuItem key={key} value={lng}>
                      {config.languageTitles[lng]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>

        <PasswordModal
          open={passwordModalOpen}
          isValid={isPasswordValid}
          handleClose={() => setPasswordModalState(false)}
          onSubmit={isOldPasswordSystem ? handlePasswordSubmitOld : handlePasswordSubmit}
          theme={currentTheme}
        />
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme()(withStyles(styles)(ControlBar)));
