import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ExplanationMessage from "../ExplanationMessage";
import ControlBar, { IValueState } from "../ControlBar/ControlBar";
import TestsContainer from "../TestsContainer/TestsContainer";
import AdditionalTestsContainer from "../TestsContainer/AdditionalTestsContainer";
import ProgressBar from "../ProgressBar/ProgressBar";
import logo from "../../assets/images/logo.jpg";
import PrintInfoBox from "../PrintInfoBox";
import LogsComponent from "../LogModal/logs";
import "./MainContent.scss";
import { LogModalProvider } from "../LogModal/LogModalProvider";
import PermissionModal from "../PermissionModal";
import useMediaDevices from "../../helpers/customHooks/usePermissionTracker";
import useExtensionTracker from "../../helpers/customHooks/useExtensionTracker";
import CompanionModal from "../CompanionModal/companionModal";
import { ILog, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography } from "@material-ui/core";
import {
  ChevronDownIcon,
  LogErrorIcon,
  LogWarningIcon,
  OpenInNewIcon,
  RerunIcon,
} from "../../assets/icons";
import { format, parseISO } from "date-fns";
import { IField } from "../../interfaces";
import { getQueryParameters } from "../../helpers/utils";
import { startTest } from "../../actions/actions";
import ScoreDonutChart from "../TestsContainer/Tests/NewTests/UI/ScoreDonutChart";
import { StatusCodes } from "../../helpers/statusCodes";
import { useTranslation } from "react-i18next";

const styles = (theme: any): any => ({
  wrapper: {},
  container: {
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    padding: "0 15px",
  },
  powered: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 16,
  },
  powered_avatour: {
    width: 200,
    margin: "0 auto",
    paddingBottom: 0,
    background: "#fff",
  },
  poweredText: {
    fontWeight: 600,
    color: "#333",
    fontSize: 10,
  },
  poweredLogo: {
    width: 47,
    height: "auto",
    display: "block",
    marginLeft: 4,
  },
  lobbyWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    maxWidth: "1230px",
    margin: "0 auto",
    padding: "0 24px",
    width: "100%",
    paddingBottom: "32px",
    marginTop: "32px",
  },
  lobbyPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  actionBtnSection: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  actionBtn: {
    padding: "8px 22px",
    borderRadius: "4px",
    color: "white",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    border: "none",
    backgroundColor: theme.customColors.mainColor,
  },
  whatIsThisText: {
    textDecoration: "none",
    textAlign: "center",
    color: "#3577C1",
  },
  poweredBy: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "32px",
    paddingTop: "32px",
    "& span": {
      color: "black",
      fontSize: "12px",
      letterSpacing: "0.4px",
      fontWeight: 400,
      lineHeight: "19.92px",
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  formDesc: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& h1": {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "37.5px",
      textAlign: "center",
      color: "black",
      fontFamily: "Roboto, sans-serif !important",
    },
    "& h6": {
      maxWidth: "480px",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      color: "black",
      fontFamily: "Roboto, sans-serif !important",
      fontWeight: 400,
    },
  },
  formInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& input": {
      padding: "5.5px 0",
      fontFamily: "Roboto, sans-serif !important",
    },
    "& label": {
      fontFamily: "Roboto, sans-serif !important",
    },
  },
  insightWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    maxWidth: "1230px",
    margin: "0 auto",
    width: "100%",
    flex: 1,
  },
  insightContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "0 24px",
    "@media (min-width: 1070px)": {
      "& > :not(:first-child)": {
        borderRightWidth: "0px",
        borderLeftWidth: "1px",
        borderLeft: `1px solid ${theme.newPalette.other.divider}`,
      },
    },
    "@media (max-width: 1070px)": {
      flexDirection: "column",
    },
  },
  logContainer: {
    maxWidth: "797px",
    width: "100%",
    paddingLeft: "24px",
    display: "flex",
    flexDirection: "column",
    flexShrink: "0",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
    "@media (max-width: 1070px)": {
      maxWidth: "max-content",
      paddingLeft: 0,
    },
  },
  chartContainer: {
    width: "100%",
    paddingRight: "24px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  suggestionContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "16px",
    "@media (max-width: 700px)": {
      padding: "16px 0",
    },
    "&:hover": {
      background: "#fff",
      boxShadow:
        "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
    },
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "200ms",
  },
  suggestionText: {
    display: "flex",
    flex: 1,
    color: theme.newPalette.text.primary,
  },
  btnOverride: {
    border: "none",
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
    cursor: "pointer",
  },
  chart: {
    fill: "blue",
    stroke: "black",
    strokeWidth: "10px",
  },
  showBtnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  resultText: {
    color: theme.newPalette.text.primary,
    marginBottom: "32px",
  },
  errorChart: {
    fill: theme.newPalette.error.shades,
    stroke: theme.newPalette.error.main,
  },
  warningChart: {
    fill: theme.newPalette.warning.shades,
    stroke: theme.newPalette.warning.main,
  },
  successChart: {
    fill: theme.newPalette.chartSuccess.shades,
    stroke: theme.newPalette.chartSuccess.main,
  },
  chartTitle: {
    fontSize: "38px",
    fontWeight: 700,
    letterSpacing: "-0.5px",
    lineHeight: "57px",
  },
  insightInfo: {
    padding: "16px 24px 0 24px",
    "& p": {
      color: theme.newPalette.text.secondary,
    },
  },
  showBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "4px 3px 4px 5px",
    color: theme.newPalette.secondary.main,
    fontSize: "13px",
    lineHeight: "22px",
    letterSpacing: "0.46px",
    fontWeight: 500,
    textTransform: "uppercase",
    transitionProperty: "all",
    transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
    transitionDuration: "200ms",
  },
  openNewIcon: {
    padding: "4px",
    borderRadius: "9999px",
    "&:hover": {
      background: "rgba(36, 53, 68, 0.04)",
    },
    cursor: "pointer",
  },
});
function mapStateToProps(state: any) {
  return {
    backgroundColor: state.document.layout.backgroundColor,
    hidePoweredByRTC: state.document.layout.hidePoweredByRTC,
    theme: state.document.theme,
    config: state.tests.config,
    testIsLoading: state.document.testIsLoading,
    testResult: state.tests.testResult,
    logs: state.tests.logs,
    isReady: state.tests.isReady,
    createDate: state.tests.createDate,
    uuid: state.document.uuid,
    email: state.tests.email,
    fields: state.document.fields || [],
    tests: state.tests,
    testsList: state.tests.testsList,
    scoringResult: state.tests.scoringResult,
    rulesEngineResult: state.tests.rulesEngineResult,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    onStartTests(testsList: string[], config: any) {
      dispatch(startTest(testsList, config));
    },
  };
}

interface IMainContentProps {
  classes: any;
  backgroundColor?: string;
  hidePoweredByRTC?: boolean;
  theme?: any;
  config?: any;
  testIsLoading?: boolean;
  testResult?: ITestsResult;
  logs?: ILog[];
  isReady?: boolean;
  createDate?: any;
  uuid?: string;
  email?: string;
  fields?: IField[];
  tests?: any;
  onStartTests(testsList: string[], config: any): void;
  testsList: string[];
  scoringResult: any;
  rulesEngineResult: RulesEngineResult;
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

export type TestState = "initial" | "form" | "inProgress";

interface Issue {
  type: string;
  message: string;
  id: number;
}

interface Suggestion {
  color: string;
  message: string;
}

type RulesEngineResult = {
  issues: Issue[];
  suggestions: Suggestion[];
};

const MainContent = (props: IMainContentProps) => {
  const {
    classes,
    backgroundColor,
    hidePoweredByRTC,
    theme,
    config,
    testResult,
    logs,
    isReady,
    createDate,
    uuid,
    testIsLoading,
    email,
    fields,
    tests,
    onStartTests,
    testsList,
    scoringResult,
    rulesEngineResult,
  } = props;
  const [testState, setTestState] = useState<TestState>("initial");
  const [values, setValues] = React.useState<IValueState>({});
  const { t } = useTranslation(["tests"]);
  const [campanionModalShow, setCampanionModalShow] = useState<boolean>(false);

  useEffect(() => {
    const valuesState: IValueState = {};
    const backwardCompatibilityValues = tests || {};
    const queryParams = getQueryParameters();
    fields?.forEach((field) => {
      const value = backwardCompatibilityValues[field.name] || queryParams[field.name] || "";
      valuesState[field.name] = value;
    });
    setValues(valuesState);
  }, [fields]);

  const startTest = (testsList: string[], config: any) => {
    config = {
      ...config,
      fieldsValues: values,
    };
    onStartTests(testsList, config);
    setTestState?.("inProgress");
  };
  const testReload = () => {
    const isCompanionForced = config?.options?.companion === "force";
    if (isCompanionForced) {
      const isChrome =
        navigator.userAgent.includes("Chrome") && typeof chrome !== "undefined" && chrome.runtime;
      const extensionId = "ninnkfgcogbifinffmalccnoijlhhnok";
      if (isChrome) {
        return new Promise((resolve, reject) => {
          chrome.runtime.sendMessage(extensionId, { action: "GET_EXTENSION_INFO" }, (response) => {
            if (!chrome.runtime.lastError) {
              setCampanionModalShow(false);
              startTest(testsList, config);
              resolve(true);
            } else {
              setCampanionModalShow(true);
              resolve(false);
            }
          });
        });
      } else {
        setCampanionModalShow(true);
        return false;
      }
    } else {
      setCampanionModalShow(false);
      startTest(testsList, config);
      return true;
    }
  };
  const isCompanionForced = props.config
    ? props.config.options["companion"] && props.config.options["companion"] === "force"
      ? true
      : false
    : false;
  //commenting the actuall condition and hiding the hidePermissionModal for https://redmine.testrtc.com/issues/9451
  // let hidePermissionModal = useMediaDevices(config);
  let hidePermissionModal = true;
  const [hideInstallExtensionModal, setHideInstallExtensionModal] = useState<boolean>(
    !isCompanionForced
  );

  let checkExtension = useExtensionTracker();

  const showExtention = (state: boolean) => {
    setHideInstallExtensionModal(state);
  };
  const [showMore, setShowMore] = useState(false);
  const testsContainerRef = useRef<HTMLDivElement | null>(null);

  let scoringStatus = scoringResult?._global.status;

  const statusColor =
    scoringStatus === "good"
      ? "#007E46"
      : scoringStatus === "poor"
      ? "#FC9A25"
      : scoringStatus === "fail"
      ? "#D32F2F"
      : undefined;

  const statusTitle =
    scoringStatus === "good"
      ? t("tests:testStatus.good")
      : scoringStatus === "poor"
      ? t("tests:testStatus.fair")
      : scoringStatus === "fail"
      ? t("tests:testStatus.critical")
      : undefined;
  const testStatus = testResult?.status;

  const chartClass =
    testStatus === "green"
      ? classes.successChart
      : testStatus === "yellow"
      ? classes.warningChart
      : testStatus === "red"
      ? classes.errorChart
      : undefined;

  const chartTitle =
    testStatus === "green"
      ? t("tests:testStatus.good")
      : testStatus === "yellow"
      ? t("tests:testStatus.fair")
      : testStatus === "red"
      ? t("tests:testStatus.poor")
      : undefined;

  const titleColor =
    testStatus === "green"
      ? "#007E46"
      : testStatus === "yellow"
      ? "#FC9A25"
      : testStatus === "red"
      ? "#D32F2F"
      : undefined;

  useEffect(() => {
    if (showMore && testsContainerRef.current) {
      testsContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showMore]);

  const findCodeLinkById = (id: number) => {
    const match = StatusCodes.find((statusCode) => statusCode.statusCode === id);
    return match ? match.codeLink : "#";
  };

  return (
    <LogModalProvider>
      {config?.useNewWidgetDesign ? (
        <>
          {testResult && Object.keys(testResult).length > 0 && (
            <div className={classes.insightWrapper}>
              <div className={classes.insightInfo}>
                <Typography variant="body2">
                  {t("tests:resultPage.user-details")}: Email: {email} |{" "}
                  {t("tests:resultPage.run-time")}:{" "}
                  {isReady && !testIsLoading && uuid && (
                    <>
                      {format(
                        parseISO(createDate ? createDate : new Date().toISOString()),
                        "MM-dd-yyyy, HH:mm"
                      )}
                    </>
                  )}
                </Typography>
              </div>
              <div className={classes.insightContainer}>
                <div className={classes.chartContainer}>
                  <Typography variant="h2" className={classes.resultText}>
                    {scoringResult
                      ? t("tests:resultPage.scoring-result-title1")
                      : t("tests:resultPage.scoring-result-title2")}
                  </Typography>
                  {scoringResult ? (
                    <ScoreDonutChart
                      x={scoringResult._global.scaledScore}
                      activeColor={statusColor}
                      statusTitle={statusTitle}
                    />
                  ) : (
                    <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                      <ellipse
                        cx="100"
                        cy="100"
                        rx="95"
                        ry="95"
                        strokeWidth="10px"
                        className={chartClass}
                      />
                      <text
                        x="50%"
                        y="50%"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        className={`${classes.chartTitle} ${titleColor}`}
                        fill={titleColor}
                      >
                        {chartTitle}
                      </text>
                    </svg>
                  )}
                  <button
                    className={`${classes.btnOverride} ${classes.showBtn}`}
                    style={{
                      marginTop: !scoringResult ? "32px" : "",
                    }}
                    onClick={() => testReload()}
                  >
                    <RerunIcon />
                    <span>{t("tests:resultPage.rerun")}</span>
                  </button>
                </div>
                {campanionModalShow && <CompanionModal open={campanionModalShow} />}
                {rulesEngineResult?.issues?.length > 0 && (
                  <div className={classes.logContainer}>
                    {rulesEngineResult?.issues.map((issue, index) => {
                      const codeLink = findCodeLinkById(issue.id);
                      return (
                        <div className={classes.suggestionContainer} key={index}>
                          {issue.type === "Critical" ? (
                            <LogErrorIcon />
                          ) : issue.type === "Fair" ? (
                            <LogWarningIcon />
                          ) : null}
                          <div className={classes.suggestionText}>
                            <Typography variant="body1">{issue.message}</Typography>
                          </div>
                          <a
                            target="_blank"
                            href={codeLink}
                            className={`${classes.btnOverride} ${classes.openNewIcon}`}
                          >
                            <OpenInNewIcon />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className={classes.showBtnContainer}>
                {!showMore && (
                  <button
                    className={`${classes.btnOverride} ${classes.showBtn}`}
                    onClick={() => setShowMore(!showMore)}
                  >
                    <span>{t("tests:resultPage.show-more")}</span>
                    <span
                      style={{
                        transform: showMore ? " rotate(180deg)" : " rotate(0deg)",
                      }}
                    >
                      <ChevronDownIcon />
                    </span>
                  </button>
                )}
              </div>
            </div>
          )}
          <div className={classes.lobbyWrapper} ref={testsContainerRef} style={{ height: "100vh" }}>
            <ControlBar
              hideInstallExtensionModal={hidePermissionModal}
              testState={testState}
              setTestState={setTestState}
              onboardingClasses={classes}
              showMore={showMore}
              showExtension={showExtention}
              showCompanionModal={campanionModalShow}
              setShowCompanionModal={setCampanionModalShow}
            />
            {config &&
              config.options.hidewidgets !== "true" &&
              props.testResult &&
              Object.keys(props.testResult).length > 0 &&
              showMore && (
                <React.Fragment>
                  <TestsContainer />
                </React.Fragment>
              )}
            {showMore && !testIsLoading && (
              <div className={classes.showBtnContainer}>
                <button
                  className={`${classes.btnOverride} ${classes.showBtn}`}
                  onClick={() => setShowMore(!showMore)}
                  style={{
                    marginTop: "32px",
                  }}
                >
                  <span>{t("tests:resultPage.show-less")}</span>
                  <span
                    style={{
                      transform: showMore ? " rotate(180deg)" : " rotate(0deg)",
                    }}
                  >
                    <ChevronDownIcon />
                  </span>
                </button>
              </div>
            )}
            {!hidePoweredByRTC && testResult === null && (
              <div className={classes.poweredBy}>
                <span>Powered by</span>
                <img alt="Powered by" src="/testRTC.png" width={70} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className={`${classes.wrapper} main-content-wrapper-${theme}`}
          style={wrapperStyle(backgroundColor)}
        >
          <div className={classes.container}>
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <ExplanationMessage />
                <PrintInfoBox />
              </React.Fragment>
            )}
            <ControlBar
              hideInstallExtensionModal={hideInstallExtensionModal}
              showExtension={showExtention}
              showCompanionModal={campanionModalShow}
              setShowCompanionModal={setCampanionModalShow}
            />
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <TestsContainer />
                <AdditionalTestsContainer />
              </React.Fragment>
            )}
            {props.config &&
              props.config.options &&
              props.config.options["report"] === "pdf" &&
              props.config.includeLogs && (
                <React.Fragment>
                  <div className="html2pdf__page-break"></div>
                  <LogsComponent scrollable={false} />
                </React.Fragment>
              )}
            {props.testIsLoading && <CompanionModal open={!hideInstallExtensionModal} />}
            {props.testIsLoading && hideInstallExtensionModal && (
              <PermissionModal open={!hidePermissionModal} />
            )}
            {hidePermissionModal && hideInstallExtensionModal && <ProgressBar />}

            {!hidePoweredByRTC && (
              <a
                className={`poweredBy ${classes.powered} ${classes[`powered_${theme}`]}`}
                href="https://testrtc.com"
                target="_blank"
              >
                <span className={classes.poweredText}>Powered by</span>
                <img className={classes.poweredLogo} src={logo} alt="logo" />
              </a>
            )}
          </div>
        </div>
      )}
    </LogModalProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainContent));
