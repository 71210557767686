import * as React from "react";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";

import { IField } from "../../interfaces";
import { useTranslation } from "react-i18next";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import CustomTooltip from "../TestsContainer/Tests/NewTests/UI/CustomTooltip";

interface IFieldProps {
  classes?: any;
  currentTheme?: any;
  name: string;
  value: string | number;
  meta: IField;
  style?: any;
  onChange?: (e: any) => any;
  config: TestConfiguration;
  onboardingClasses?: any;
}

const styles = (theme: any) => ({
  textField: {
    margin: 0,
    borderLeft: "1px solid #c9cbd1",
    // padding: "0 15px",
    padding: "0px 15px",
    justifyContent: "center",
    minWidth: "100px",
    background: "#fff",
    "&:focus": {
      background: "#000",
    },
    "&:first-child": {
      borderLeft: 0,
    },
    "& > div": {
      fontSize: 14,
      fontWeight: 600,
      padding: 7,
    },
    "& div.error": {
      borderBottom: "2px solid red",
      marginBottom: 2,
    },
    "& > div:before": {
      display: "none",
    },
    "& > div:after": {
      display: "none",
    },
  },
  textField_freshworks: {
    "& > div": {
      fontWeight: 500,
    },
  },

  textField_puzzel: {
    border: "0px solid #CFCFCF",
    padding: "0",
    background: "none",
  },

  input_puzzel: {
    border: "1px solid #CFCFCF",
    borderRadius: "8px",
    height: "40px !important",
    backgroundColor: "white",
    padding: "2px 10px",
  },
  input_dialpad: {
    border: "1px solid #CFCFCF",
    borderRadius: "8px",
    height: "40px !important",
    backgroundColor: "white",
    padding: "2px 10px",
    fontWeight: 500,
    fontSize: "15px",
  },

  textField_mend: {
    "& > div": {
      fontWeight: 500,
    },
  },
  input_salesloft: {
    "&::placeholder": {
      color: theme.customColors.mainColor,
      opacity: 1,
    },
  },
});

const Field = (props: IFieldProps) => {
  const { t } = useTranslation(["common", "tests"]);

  const { classes, currentTheme, name, value, meta, style, onChange, config } = props;
  const isSelect = meta?.variant === "select" && meta?.options?.length > 0;
  const label_key = meta?.label.replace(/ /g, "_").toLowerCase() + "_placeholder_key";
  const label = t(label_key) !== label_key ? t(label_key) : meta?.label;

  function transformMetaLabel(label: string): string {
    switch (label) {
      case "Add Reason":
        return "Reason";
      case "Your Email":
        return "Email";
      default:
        return label;
    }
  }

  if (config.useNewWidgetDesign) {
    return (
      <CustomTooltip title={value}>
        <TextField
          id={name}
          name={name}
          label={transformMetaLabel(label)}
          variant="standard"
          value={value}
          error={meta?.error}
          onChange={onChange}
          InputProps={{
            readOnly: meta?.readonly,
            error: meta?.error,
            className: meta?.error ? "error" : "",
          }}
          type={meta?.type || "text"}
        />
      </CustomTooltip>
    );
  }

  return (
    <TextField
      id={name}
      name={name}
      type={meta?.type || "text"}
      placeholder={label}
      className={`${classes.textField} ${
        classes[`textField_${currentTheme}`]
      } control-bar-form__input`}
      value={value ? value : isSelect ? meta?.options[0].value : value}
      onChange={onChange}
      margin="normal"
      InputProps={{
        readOnly: meta?.readonly,
        error: meta?.error,
        className: meta?.error ? "error" : "",
        classes: { input: classes[`input_${currentTheme}`] },
      }}
      error={meta?.error}
      autoFocus={!!meta?.autoFocus}
      style={style}
      fullWidth={true}
      select={isSelect}
    >
      {isSelect &&
        meta.options.map((x, idx) => {
          return (
            <MenuItem key={x.value} value={x.value} selected={idx === 0} disabled={idx === 0}>
              {x.label}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default withTheme()(withStyles(styles)(Field));
